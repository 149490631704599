import React, { useEffect } from "react";
import classes from "./payment.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  paymentSuccessApi,
  setHeaderName,
} from "../../../Redux/actions/userActions";
import {
  BrudCrumsNextIcon,
  CloseIcon,
  MoreHorizontal,
  RoundQuestionMark,
} from "../../../Assets/Icons/icons";
import { useCreditCardValidator, images } from "react-creditcard-validator";
import { Col, Collapse, Row } from "react-bootstrap";
import {
  Card,
  Divider,
  TextField,
  Typography,
  Grow,
  Button,
} from "@mui/material";
import card from "../../../Assets/Images/Card.png";
import paypal from "../../../Assets/Images/Paypal.png";
import applePay from "../../../Assets/Images/applePay.png";
import { useState } from "react";
import Footer from "../../../Components/Footer";
import PaymentSuccessModel from "./paymentSuccessModel";
import {
  getCouponWithCode,
  getOrderDetails,
} from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";
import CheckoutForm from "./PaymentNew";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHER_KEY_TEST);

const PaymentMethod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState(false);
  const [modalToggle, setModalToggle] = useState(false);
  const convenianceFee = 10;
  const { userInfo, invoice } = useSelector((state) => state.userLogin);
  const [coupon, setCoupon] = useState(localStorage.getItem("coupon"));
  const [applyCouponChecked, setApplyCouponChecked] = useState(false);
  const [couponInfo, setCouponInfo] = useState("");
  const [myTotal, setMyTotal] = useState("");
  const [couponErr, setCouponErr] = useState("");
  const { orderDetails } = useSelector((state) => state.subscriptionData);
  const clientSecret = localStorage.getItem("secret_id");
  const [message, setMessage] = useState(null);
  console.log("couponInfo =", couponInfo);
  let err_arr = [
    "An unexpected error occurred",
    "Your payment is processing",
    "Your payment was not successful, please try again",
    "Something went wrong",
    "Something went wrong. Try Again",
  ];

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  console.log("clientSecret =", clientSecret);
  const handleNavigatePayment = () => {
    dispatch(setHeaderName("payment-details"));
    navigate("/payment-details");
  };

  useEffect(() => {
    if (userInfo && userInfo?._id) {
      dispatch(getOrderDetails(userInfo?._id, setErrorAlert));
    }
  }, [userInfo]);

  //   const handleNavigateReceipt = () => {
  //     navigate("/invoice/receipt");
  //   };

  const handleSubmit = () => {
    if (cardNumber === "" || cardNumber.length < 16) {
      setCardNumberError(true);
    }
    if (expiryDate === "" || expiryDate.length < 4) {
      setExpiryDateError(true);
    }
    if (cvv === "" || cvv.length < 3) {
      setCvvError(true);
    }
    if (
      cardNumber !== "" &&
      expiryDate !== "" &&
      cvv !== "" &&
      !erroredInputs.cardNumber &&
      !erroredInputs.expiryDate &&
      !erroredInputs.cvv
    ) {
      const userEntry = {
        user_id: userInfo._id,
        order_id: orderDetails?._id,
        subscribed_by: "SCHOOL",
        coupon_code: coupon,
      };
      // dispatch(paymentSuccessApi(userEntry, setErrorAlert, setModalToggle));
    }
  };

  const handleSavePayment = (payment_intent) => {
    const userEntry = {
      paymentIntentId: payment_intent,
    };
    dispatch(paymentSuccessApi(userEntry, setErrorAlert, setModalToggle));
  };

  const getTotalAmount = () => {
    const Amount = orderDetails?.subscriptionInfo?.price + convenianceFee;
    const discount1 = orderDetails?.subscriptionInfo?.price * 0.1;
    const discount2 = orderDetails?.subscriptionInfo?.price * 0.05;
    const total = Amount - discount1 - discount2;
    if (localStorage.getItem("coupon") && couponInfo) {
      let c_discound =
        (couponInfo / 100) * orderDetails?.subscriptionInfo?.price;
      // return total - orderDetails?.subscriptionInfo?.price * 0.05;
      return total - c_discound;
    }
    return total;
  };

  const modalClose = () => {
    setModalToggle(false);
    localStorage.removeItem("secret_id");
    localStorage.removeItem("coupon_code");
    localStorage.removeItem("coupon_info");
    localStorage.removeItem("coupon");
    if (invoice) {
      navigate(`/invoice/${invoice?._id}`);
    } else {
      navigate(`/profile`);
    }
  };

  const handleApplycoupon = () => {
    localStorage.setItem("coupon", coupon);
    if (coupon) {
      dispatch(
        getCouponWithCode(
          coupon,
          userInfo?._id,
          setApplyCouponChecked,
          applyCouponChecked,
          setCouponInfo,
          setCouponErr
        )
      );
    }
  };

  const removeCoupon = () => {
    console.log("remove coupon");
    localStorage.removeItem("coupon");
    setApplyCouponChecked(false);
    setCoupon("");
    setCouponInfo("");
  };

  const handleCardNumberChange = (e) => {
    const inputValue = e.target.value;
    setCardNumberError(false);
    if (inputValue.length <= 19) {
      // Update state or perform other actions
      setCardNumber(inputValue);
    }
  };

  const handleExpiryDateChange = (e) => {
    const inputValue = e.target.value;
    setExpiryDateError(false);
    setExpiryDate(inputValue);
  };

  const handleNavProfile = () => {
    navigate("/profile");
    dispatch(setHeaderName("Profile"));
  };

  function expDateValidate(month, year) {
    if (Number(year) > 2035) {
      return "Expiry Date Year cannot be greater than 2035";
    }
    return;
  }

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  useEffect(() => {
    if (localStorage.getItem("coupon")) {
      setCoupon(localStorage.getItem("coupon"));
      setApplyCouponChecked(false);
      // handleApplycoupon();
    }
  }, []);

  useEffect(() => {
    if (orderDetails) {
      let _total = getTotalAmount();
      setMyTotal(_total);
    }
  }, [couponInfo, orderDetails]);

  useEffect(() => {
    if (localStorage.getItem("coupon_info")) {
      setCouponInfo(localStorage.getItem("coupon_info"));
    }
  }, []);

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigatePayment()}
        >
          Payment
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigatePayment()}
        >
          Payment Details
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          Payment Method
        </span>
      </div>
      <Row className="mt-3" style={{ rowGap: "20px", marginBottom: "70px" }}>
        <Col md={6}>
          {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                message={message}
                setMessage={setMessage}
                handleSavePayment={handleSavePayment}
              />
            </Elements>
          )}
          {/* <Card style={{ padding: "20px" }}>
            <Typography className={classes.cardHeader1}>
              Select Payment Method
            </Typography>
            <div className={`mt-3 ${classes.MethodsListContainer}`}>
              <div className={classes.cardMethodBox}>
                <Card className={`${classes.methodCard} ${classes.activeCard}`}>
                  <img
                    src={card}
                    alt="Card Payment"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <Typography className={classes.MethodTxt}>Card</Typography>
                </Card>
              </div>
              <div className={classes.cardMethodBox}>
                <Card className={classes.methodCard}>
                  <img
                    src={paypal}
                    alt="Card Payment"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <Typography className={classes.MethodTxt}>Paypal</Typography>
                </Card>
              </div>
              <div className={classes.cardMethodBox}>
                <Card className={classes.methodCard}>
                  <img
                    src={applePay}
                    alt="Card Payment"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <Typography className={classes.MethodTxt}>
                    Apple Pay
                  </Typography>
                </Card>
              </div>
              <div style={{ width: "calc(100% - 81% - 45px)" }}>
                <Card
                  className={classes.methodCard}
                  style={{ alignItems: "center" }}
                >
                  <MoreHorizontal />
                </Card>
              </div>
            </div>
            <div className="mt-3" style={{ position: "relative" }}>
              <Typography className={`mb-2 ${classes.formLable}`}>
                Card Number
              </Typography>
              <input
                {...getCardNumberProps({
                  onChange: handleCardNumberChange,
                  value: cardNumber,
                  className: classes.cardNumberInput,
                  placeholder: "XXXX XXXX XXXX XXXX",
                  maxLength: 16,
                })}
              />
              <svg
                {...getCardImageProps({ images, className: classes.cardImage })}
              />
              <small className={classes.ErrorText}>
                {cardNumberError && cardNumber.length !== 16
                  ? "Invalid Card Number"
                  : cardNumberError && cardNumber.length === 0
                  ? "Card Number is Required"
                  : erroredInputs.cardNumber
                  ? erroredInputs.cardNumber
                  : null}
              </small>
            </div>
            <Row className="mt-4">
              <Col xs={6}>
                <div>
                  <Typography className={`mb-2 ${classes.formLable}`}>
                    Expiry Date
                  </Typography>
                  <input
                    {...getExpiryDateProps({
                      onChange: handleExpiryDateChange,
                      value: expiryDate,
                      className: classes.cardNumberInput,
                      placeholder: "MM/YY",
                    })}
                  />
                  <small className={classes.ErrorText}>
                    {erroredInputs.expiryDate
                      ? erroredInputs.expiryDate
                      : expiryDateError
                      ? "Expiry Date is required"
                      : null}
                  </small>
                </div>
              </Col>
              <Col xs={6} style={{ position: "relative" }}>
                <div>
                  <Typography className={`mb-2 ${classes.formLable}`}>
                    CVV
                  </Typography>
                  <input
                    {...getCVCProps({
                      onChange: (e) => {
                        setCvv(e.target.value);
                        setCvvError(false);
                      },
                      value: cvv,
                      className: `${classes.cardNumberInput}`,
                      style: {
                        width: "85%",
                        display: "flex",
                      },
                    })}
                  />
                  <small className={classes.ErrorText}>
                    {(erroredInputs.cvc && erroredInputs.cvc) ||
                      (cvvError && "Cvv is required")}
                  </small>
                </div>
                <span className={classes.questionsvg}>
                  <RoundQuestionMark />
                </span>
              </Col>
            </Row>
          </Card> */}
        </Col>
        <Col md={6}>
          <Card style={{ padding: "15px 20px" }}>
            <Typography className={classes.cardHeader1}>
              Payment Information
            </Typography>
            <div className={`mt-3 mb-2 ${classes.paymentDetailsMainDiv}`}>
              <div className={classes.paymentListDiv}>
                <Typography className={classes.paymentSubText}>
                  Payment Method
                </Typography>
                <Typography
                  className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                >
                  £{orderDetails?.subscriptionInfo?.price}.00
                </Typography>
              </div>
              <div className={classes.paymentListDiv}>
                <Typography className={classes.paymentSubText}>
                  Discount Amount (5%)
                </Typography>
                <Typography
                  className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                >
                  - £{orderDetails?.subscriptionInfo?.price * 0.05}.00
                </Typography>
              </div>
              {localStorage.getItem("coupon") && couponInfo && (
                <div
                  className={classes.paymentListDiv}
                  style={{ display: applyCouponChecked ? "none" : "flex" }}
                >
                  <Typography className={classes.paymentSubText}>
                    {localStorage.getItem("coupon")} ({couponInfo}%)
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £
                    {(couponInfo / 100) * orderDetails?.subscriptionInfo?.price}
                    .00
                  </Typography>
                </div>
              )}
              {/* {localStorage.getItem("coupon") ? (
                <div
                  className={classes.paymentListDiv}
                  style={{ display: applyCouponChecked ? "none" : "flex" }}
                >
                  <Typography className={classes.paymentSubText}>
                    {localStorage.getItem("coupon")}
                    <span
                      className={`${classes.applyButton} ${classes.remove}`}
                      onClick={() => removeCoupon()}
                    >
                      Remove
                    </span>
                  </Typography>
                  <Typography
                    className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                  >
                    - £{orderDetails?.subscriptionInfo?.price * 0.05}.00
                  </Typography>
                </div>
              ) : (
                <div
                  className={classes.paymentListDiv}
                  style={{ display: applyCouponChecked ? "none" : "flex" }}
                >
                  <Typography className={classes.paymentSubText}>
                    Coupon Discount
                  </Typography>
                  <Typography
                    className={`${classes.applyButton}`}
                    onClick={() => setApplyCouponChecked(!applyCouponChecked)}
                  >
                    Apply Coupon
                  </Typography>
                </div>
              )}
              <Grow
                in={applyCouponChecked}
                style={{
                  transformOrigin: "0 0 0",
                }}
                {...(applyCouponChecked ? { timeout: 1000 } : {})}
              >
                <div
                  className={classes.Couponcontainer}
                  style={{ display: applyCouponChecked ? "flex" : "none" }}
                >
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "15px",
                      width: "310px",
                    }}
                    id="descriptionInput"
                    fullWidth
                    size="small"
                    placeholder="Enter Coupon Code here"
                    className={classes.CouponInput}
                    value={coupon}
                    onChange={(e) => {
                      setCoupon(e.target.value);
                    }}
                  />
                  <div className={`d-flex align-items-center`}>
                    <Typography
                      className={`${classes.applyButton}`}
                      onClick={handleApplycoupon}
                    >
                      Apply
                    </Typography>
                    <Button
                      variant="text"
                      className={classes.closeButton}
                      onClick={() =>{
                        setApplyCouponChecked(!applyCouponChecked);
                        removeCoupon()
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
              </Grow> */}
              <div className={classes.paymentListDiv}>
                <Typography className={classes.paymentSubText}>
                  Convenience Fee
                </Typography>
                <Typography
                  className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                >
                  £{convenianceFee}.00
                </Typography>
              </div>
              <div className={classes.paymentListDiv}>
                <Typography className={classes.paymentSubText}>
                  10% Extra off
                </Typography>
                <Typography
                  className={`${classes.cardHeader1} ${classes.paymentTextWeight}`}
                >
                  - £{orderDetails?.subscriptionInfo?.price * 0.1}.00
                </Typography>
              </div>
            </div>
            <Divider />
            <div className={`mt-2`}>
              <div className={classes.paymentListDiv}>
                <Typography className={classes.cardHeader1}>
                  Total Amount (Inc. Tax)
                </Typography>
                <Typography className={`${classes.cardHeader1}`}>
                  £{myTotal}.00
                </Typography>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Footer
        callback={handleNavProfile}
        from={"payment"}
        edit={window.location.pathname.includes("edit")}
        submitCallBack={handleSubmit}
      /> */}
      {modalToggle && <PaymentSuccessModel modalClose={modalClose} name={""} />}
      {couponErr && (
        <AlertBox
          type="error"
          message={couponErr}
          stateName={() => setCouponErr("")}
        />
      )}

      {err_arr.includes(message) && (
        <AlertBox
          type="error"
          message={message}
          stateName={() => setMessage("")}
        />
      )}
    </div>
  );
};

export default PaymentMethod;
