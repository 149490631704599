import React, { useEffect } from "react";
import classes from "./student.module.css";
import {
  BrudCrumsNextIcon,
  CloseEyeIcon,
  ContentCopyIcon,
  ExcelFileIcon,
  ExcelIcon,
  InfoOutlineIcon,
  OpenEyeIcon,
  RefreshIcon,
} from "../../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import {
  Card,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Button,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Alphabetic,
  Numeric,
  generatePassword,
} from "../../../Utilities/commonFunctions";
import Footer from "../../../Components/Footer";
import {
  AddStudentsBulk,
  SaveSelectedFile,
  addStudent,
  downloadEmailTemplate,
  getOneStudent,
  getSubscriptionList,
  updateStudent,
} from "../../../Redux/actions/studentAction";
import AlertBox from "../../../Components/AlertBox";
import { getAllClasses } from "../../../Redux/actions/classActions";
import CommanDropDown from "../../../Components/DragAndDrop";
import BulkUploadSuccessModal from "./BulkUploadSuccessModal";
import _debounce from "lodash/debounce";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';

const AddStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSchool, setSelectedSchool] = useState("select");
  const [studentID, setStudentID] = useState("");
  const [entryType, setEntryType] = useState("individual");
  const [gradeError, setGradeError] = useState(false);
  const [sectionError, setSectionError] = useState(false);
  const [intrestError, setIntrestError] = useState(false);
  const [accountEmailError, setAccountEmailError] = useState(true);
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [age, setAge] = useState("");
  const [subscribeBy, setSubscribeBy] = useState("SCHOOL");
  const [subscriptionLevel, setSubscriptionLevel] = useState("0");
  const [showPassword, setShowPassword] = useState(false);
  const [grade, setGrade] = useState("0");
  const [section, setSection] = useState("0");
  const [intrest, setIntrest] = useState("0");
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentMobile, setParentMobile] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [generateClicked, setGenerateClicked] = useState(false);
  const [password, setPassword] = useState("");
  const [showSchool, setShowSchool] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [userEntryArray, setUserEntryArray] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [defaultSuccess, setDefaultSuccess] = useState("");
  const [defaultSuccessMessage, setDefaultSuccessMessage] = useState("");
  const [defaultErrorMessage, setDefaultErrorMessage] = useState();
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const {
    success,
    error,
    oneStudent,
    subscriptionList,
    studentFile,
    bulkUpdateList,
  } = useSelector((state) => state.studentDatas);

  const { customerSubscriptionList } = useSelector(
    (state) => state.subscriptionData
  );
  // const { subscriptionList } = useSelector((state) => state.subscriptionDatas);
  const { classesList } = useSelector((state) => state.classDatas);
  // const { schoolsNameList } = useSelector((state) => state.schoolDatas);
  const { userInfo, userRole } = useSelector((state) => state.userLogin);

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      if (userInfo) {
        dispatch(getAllClasses(setErrorAlert, "", userInfo?.schoolInfo?._id));
        dispatch(getSubscriptionList(setErrorAlert));
        console.log("userinfo subscription level", oneStudent?.subscription);
        // setSubscriptionLevel(oneStudent?.subscription);
        // console.log("subscritption level", subscriptionLevel);
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, [dispatch, subscribeBy, userInfo]);

  useEffect(() => {
    const path = window?.location?.pathname?.split("/");
    if (path[path.length - 1] === "2") {
      setEntryType("bulk");
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      setSelectedSchool(oneStudent?.school);
      setSubscribeBy(oneStudent?.subscribed_by);
      // setStudentName(`${oneStudent?.first_name} ${oneStudent?.last_name}`);
      setStudentID(oneStudent?.student_id);
      setStudentFirstName(oneStudent?.first_name);
      setStudentLastName(oneStudent?.last_name);
      setAge(oneStudent?.age);
      setSubscriptionLevel(oneStudent?.subscription);
      setGrade(oneStudent?.gradeId);
      setSection(oneStudent?.section);
      setIntrest(oneStudent?.interest);
      setParentName(oneStudent?.parent?.name);
      setParentEmail(oneStudent?.parent?.email);
      setParentMobile(oneStudent?.parent?.phone);
      setAccountEmail(oneStudent?.parent?.email);
      setCountryCode(oneStudent?.parent?.country_code);
      setSelectedCountry(oneStudent?.parent?.country_id);
      setShowSchool(true);
    }
  }, [oneStudent]);
  console.log("This is one student ", oneStudent);
  console.log("country_code = ", oneStudent?.parent?.country_code);
  console.log("country_id = ", oneStudent?.parent?.country_id);

  const copyTrst = (text) => {
    copy(text);
    setDefaultSuccess(true);
    setDefaultSuccessMessage("Password has Copied Successfully");
  };

  const handleDownloadTemplate = () => {
    dispatch(pageLoader(true));
    dispatch(downloadEmailTemplate(setErrorAlert, setSuccessAlert));
  };

  useEffect(() => {
    const delayedEffect = _debounce(() => {
      const path = window.location.pathname;
      const arr = path?.split("/");
      if (window.location.pathname.includes("edit")) {
        const path = window.location.pathname;
        const arr = path?.split("/");
        dispatch(pageLoader(true));
        dispatch(getOneStudent(arr[arr.length - 1], setErrorAlert));
      } else {
        if (arr[arr.length - 1] === "school") {
          setSubscribeBy("SCHOOL");
          setShowSchool(true);
        } else {
          setSubscribeBy("PARENT");
          setShowSchool(false);
        }
      }
    }, 100); // Adjust the debounce time as needed

    delayedEffect();

    return () => {
      delayedEffect.cancel(); // Cancel the debounced effect if it hasn't run yet
    };
  }, []);

  const closeModel = () => {
    setSuccessModal(false);
    handleNavigateStudent();
  };

  const handleRadioChange = (event) => {
    setSubscribeBy(event.target.value);
    setSubscriptionLevel("0");
  };

  const handleChange = (e) => {
    setSelectedSchool(e.target.value);
    setGrade("0");
    setSection("0");
  };

  const handleNavigateStudent = () => {
    dispatch(setHeaderName("students"));
    navigate("/students");
  };

  const handleSubmit = () => {
    console.log("clicked submit");
    if (entryType === "individual") {
      // if (parentMobile?.length != 10) {
      //   setIsValid(false);
      // } else 
      if (!isValid) {
        setIsValid(false);
      }
      else if (grade === "0") {
        setGradeError(true);
      }
      else if (section === "0") {
        setSectionError(true);
      }
      else if (intrest === "0") {
        setIntrestError(true);
      }
      else if (grade !== "0" && section !== "0" && intrest !== "0") {
        const userEntry = {
          student_id: studentID,
          name: `${studentFirstName} ${studentLastName}`,
          age: age.toString(),
          subscribed_by: subscribeBy,
          subscription: subscriptionLevel,
          gradeId: grade,
          interest: intrest,
          parent_name: parentName,
          email: parentEmail,
          phone: parentMobile,
          section: section,
          country_code: countryCode,
          country_id: selectedCountry,
        };
        dispatch(pageLoader(true));
        if (window.location.pathname.includes("edit")) {
          userEntry.Id = oneStudent._id;
          console.log("userEntry", userEntry);
          dispatch(updateStudent(userEntry, setSuccessAlert, setErrorAlert));
        } else {
          userEntry.password = password;
          userEntry.school = userInfo?.schoolInfo?._id;
          console.log("userEntry", userEntry);
          dispatch(addStudent(userEntry, setSuccessAlert, setErrorAlert));
        }
      }
    } else {
      console.log("Bulk User Entry", userEntryArray);
      if (userEntryArray && userEntryArray.length > 0) {
        dispatch(pageLoader(true));
        dispatch(
          AddStudentsBulk(userEntryArray, setSuccessModal, setErrorAlert)
        );
      } else {
        setDefaultErrorMessage(
          "Please add student details it Seems like Empty Excel"
        );
        setErrorAlert(true);
      }
    }
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setParentMobile("");
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setParentMobile(value);
  };

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
    setDefaultSuccess();
    setDefaultErrorMessage();
  };

  const handleSelectFile = (file, array) => {
    console.log(file, array);
    // setStudentFile(file);
    setUserEntryArray(array);
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateStudent()}
        >
          Students
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={`${classes.TableBodyText} ${classes.HeadingText}`}>
          {window.location.pathname.includes("edit")
            ? `${oneStudent?.first_name} ${oneStudent?.last_name}`
            : "Add Student"}
        </span>
      </div>
      {!window.location.pathname.includes("edit") && (
        <div>
          <Typography className={`${classes.AddStudentMainHEader} mt-4 mb-3`}>
            Add New Student
          </Typography>
          <Col md={4}>
            <div className="d-flex justify-content-between align-items-center">
              <Card
                className={
                  entryType === "individual"
                    ? `${classes.btnCardGrade} ${classes.activeCard}`
                    : classes.btnCardGrade
                }
                onClick={() => {
                  setEntryType("individual");
                  navigate("/add-student/school/1");
                }}
              >
                <Typography>Individual</Typography>
              </Card>
              <Card
                className={
                  entryType === "bulk"
                    ? `${classes.btnCardGrade} ${classes.activeCard}`
                    : classes.btnCardGrade
                }
                onClick={() => {
                  setEntryType("bulk");
                  navigate("/add-student/school/2");
                  dispatch(SaveSelectedFile({}));
                }}
              >
                <Typography>Bulk</Typography>
              </Card>
            </div>
          </Col>
        </div>
      )}
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={classes.AddStudentForm}
      >
        {entryType === "individual" ? (
          <div>
            <Card
              className={`mt-4`}
              style={{ padding: "20px", textAlign: "left" }}
            >
              <Typography className={classes.StudentPageMainHeading}>
                Basic Details
              </Typography>
              <Row className="mt-3">
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Student Id
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="studentidInput"
                      fullWidth
                      placeholder="Enter student ID"
                      className={classes.TextInput}
                      value={studentID}
                      onChange={(e) => {
                        setStudentID(e.target.value);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Student ID is required"]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Student First Name
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="studentfirstnameInput"
                      fullWidth
                      placeholder="Enter student first name"
                      className={classes.TextInput}
                      value={studentFirstName}
                      onChange={(e) => {
                        setStudentFirstName(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        Alphabetic(e);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Student First Name is required"]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Student Last Name
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="studentlastnameInput"
                      fullWidth
                      placeholder="Enter student last name"
                      className={classes.TextInput}
                      value={studentLastName}
                      onChange={(e) => {
                        setStudentLastName(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        Alphabetic(e);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Student Last Name is required"]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Age
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="ageInput"
                      fullWidth
                      placeholder="Enter age"
                      className={classes.TextInput}
                      value={age}
                      onChange={(e) => {
                        setAge(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        Numeric(e);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Age is required"]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Grade
                    </Typography>
                    <TextValidator
                      select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="gradeInput"
                      fullWidth
                      placeholder="Select grade"
                      className={
                        grade === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      value={grade}
                      onChange={(e) => {
                        setGrade(e.target.value);
                        if (e.target.value !== "0") {
                          setGradeError(false);
                        }
                      }}
                      size="small"
                      error={gradeError}
                      helperText={gradeError && "Grade is required"}
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select Grade
                      </MenuItem>
                      {classesList.map((item, index) => {
                        const isActive = item.terminology.some(
                          (section) => section.isActive
                        );
                        if (isActive) {
                          return (
                            <MenuItem
                              key={index}
                              value={item._id}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.grade?.charAt(0).toUpperCase() +
                                item.grade
                                  ?.slice(1)
                                  .toLowerCase()
                                  .replaceAll("_", " ")}
                            </MenuItem>
                          );
                        }
                      })}
                    </TextValidator>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Section
                    </Typography>
                    <TextValidator
                      select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="sectionInput"
                      fullWidth
                      className={
                        section === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      value={section}
                      onChange={(e) => {
                        setSection(e.target.value);
                        if (e.target.value !== "0") {
                          setSectionError(false);
                        }
                      }}
                      size="small"
                      error={sectionError}
                      helperText={sectionError && "Section is required"}
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select Section
                      </MenuItem>
                      {classesList
                        .find((item) => item._id === grade)
                        ?.terminology?.filter((item) => item.isActive === true)
                        ?.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item.section}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.section?.charAt(0).toUpperCase() +
                                item.section
                                  ?.slice(1)
                                  .toLowerCase()
                                  .replaceAll("_", " ")}
                            </MenuItem>
                          );
                        })}
                    </TextValidator>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Parent Name
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="parentnameInput"
                      fullWidth
                      placeholder="Enter parent name"
                      className={classes.TextInput}
                      value={parentName}
                      onChange={(e) => {
                        setParentName(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        Alphabetic(e);
                      }}
                      size="small"
                      validators={["required"]} // Add this line
                      errorMessages={["Parent Name is required"]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Parent Email ID
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="parentemailInput"
                      fullWidth
                      placeholder="Enter parent email ID"
                      className={classes.TextInput}
                      value={parentEmail}
                      onChange={(e) => {
                        setParentEmail(e.target.value);
                        if (e.target.value !== accountEmail) {
                          setGenerateClicked(false);
                          setPassword("");
                        }
                      }}
                      size="small"
                      validators={["required", "isEmail"]} // Add this line
                      errorMessages={[
                        "Parent Email ID is required",
                        "Enter a valid email ID",
                      ]}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Parent Contact Number
                    </Typography>
                    {/* <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="parentMobileInput"
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      placeholder="Enter Parent Contact Number"
                      className={classes.TextInput}
                      value={parentMobile}
                      onChange={(e) => {
                        setParentMobile(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        Numeric(e);
                      }}
                      size="small"
                      validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                      errorMessages={[
                        "Parent Contact Number is required",
                        "Enter a valid contact number",
                      ]}
                    /> */}
                    {selectedCountry && <div className={classes.new_countrycode_field} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={parentMobile}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Interested Section
                    </Typography>
                    <TextValidator
                      select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="studentnameInput"
                      fullWidth
                      placeholder="Select grade"
                      className={
                        intrest === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      value={intrest}
                      onChange={(e) => {
                        setIntrest(e.target.value);
                        if (e.target.value !== "0") {
                          setIntrestError(false);
                        }
                      }}
                      size="small"
                      error={intrestError}
                      helperText={
                        intrestError && "Interested Section is required"
                      }
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select Interested Section
                      </MenuItem>
                      {["block_coding", "python_coding", "react_coding"].map(
                        (item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item.replace("_", " ")}
                            </MenuItem>
                          );
                        }
                      )}
                    </TextValidator>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Subscription level Selected by
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={subscribeBy}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="SCHOOL"
                        className={classes.FormRadio}
                        control={<Radio />}
                        label="School"
                      />
                      <FormControlLabel
                        value="PARENT"
                        className={classes.FormRadio}
                        control={<Radio />}
                        label="Parent"
                      />
                    </RadioGroup>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Select Subscription Level
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                        // background:
                        //   subscribeBy === "SCHOOL" ? "#DADAE1" : "#fff",
                      }}
                      // disabled={subscribeBy === "SCHOOL"}
                      hiddenLabel
                      id="subscriptonLevelInput"
                      fullWidth
                      select
                      value={subscriptionLevel}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSubscriptionLevel(e.target.value);
                      }}
                      size="small"
                      className={
                        subscriptionLevel === "0"
                          ? `${classes.defaultMenuItem} ${classes.DropdownTextInput}`
                          : `${classes.DropdownTextInput}`
                      }
                      validators={["required"]} // Add this line
                      errorMessages={["Subscription Level is required"]}
                    >
                      <MenuItem value="0" style={{ display: "none" }}>
                        Select subscription Level
                      </MenuItem>
                      {customerSubscriptionList &&
                        customerSubscriptionList.length > 0 &&
                        customerSubscriptionList.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item?.subscriptionInfo?._id}
                            >
                              {item?.subscriptionInfo?.subscription_name}
                            </MenuItem>
                          );
                        })}
                    </TextValidator>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card
              className={`mt-3`}
              style={{
                padding: "20px",
                textAlign: "left",
                marginBottom: userRole !== "TEACHER" ? "60px" : "0px",
              }}
            >
              <Typography className={classes.StudentPageMainHeading}>
                Account Details
              </Typography>
              <Row className="mt-3">
                <Col md={4}>
                  <div>
                    <Typography
                      className={`${classes.TableBodyText} ${classes.HeadingText}`}
                    >
                      Account Email ID
                    </Typography>
                    <TextValidator
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "15px",
                      }}
                      id="accountEmailInput"
                      fullWidth
                      placeholder="Enter account email ID"
                      className={classes.TextInput}
                      value={accountEmail}
                      onChange={(e) => {
                        setAccountEmail(e.target.value);
                        setAccountEmailError(parentEmail === e.target.value);
                        if (e.target.value !== parentEmail) {
                          setGenerateClicked(false);
                          setPassword("");
                        }
                      }}
                      size="small"
                      validators={["required", "isEmail"]} // Add this line
                      errorMessages={[
                        "Parent Name is required",
                        "Enter a valid email ID",
                      ]}
                      error={!accountEmailError}
                      helperText={
                        !accountEmailError
                          ? "Account Email ID does not match with Parent Email ID"
                          : ""
                      }
                    />
                  </div>
                </Col>
                {window.location.pathname.includes("edit") ? // <Col md={4}>
                //   <div style={{ position: "relative" }}>
                //     <Typography
                //       className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                //     >
                //       Password
                //     </Typography>
                //     <TextValidator
                //       sx={{
                //         "& legend": { display: "none" },
                //         "& fieldset": { top: 0 },
                //         marginBottom: "15px",
                //       }}
                //       id="outlined-adornment-password"
                //       type={showPassword ? "text" : "password"}
                //       fullWidth
                //       placeholder="Enter Password"
                //       className={classes.TextInput}
                //       size="small"
                //       value={password}
                //       onChange={(e) => setPassword(e.target.value)}
                //     />
                //     <div className={classes.showPassword}>
                //       {showPassword ? (
                //         <Button
                //           variant="text"
                //           style={{ minWidth: "unset" }}
                //           onClick={() => setShowPassword(false)}
                //         >
                //           <OpenEyeIcon />
                //         </Button>
                //       ) : (
                //         <Button
                //           variant="text"
                //           style={{ minWidth: "unset" }}
                //           onClick={() => setShowPassword(true)}
                //         >
                //           <CloseEyeIcon />
                //         </Button>
                //       )}
                //     </div>
                //   </div>
                // </Col>
                null : (
                  <Col md={3}>
                    <Button
                      className={
                        parentEmail !== accountEmail ||
                        parentEmail === "" ||
                        accountEmail === ""
                          ? `mt-3 ${classes.PassgenerateFunction} ${classes.disabledBtn}`
                          : `mt-3 ${classes.PassgenerateFunction}`
                      }
                      variant="contained"
                      disabled={
                        parentEmail !== accountEmail ||
                        parentEmail === "" ||
                        accountEmail === ""
                      }
                      onClick={() => {
                        setGenerateClicked(true);
                        generatePassword(
                          `${studentFirstName} ${studentLastName}`,
                          setPassword
                        );
                      }}
                    >
                      Generate Password
                    </Button>
                  </Col>
                )}
              </Row>
              {generateClicked && accountEmail === parentEmail ? (
                <div>
                  <Typography className={`mt-3 ${classes.passwordSentence}`}>
                    Queens Award create a uniq password for you. Once you are done,
                    Click the copy button
                  </Typography>
                  <Row className="mt-2">
                    <Col xs={10} md={4} style={{ position: "relative" }}>
                      <Typography
                        className={`${classes.TableBodyText} ${classes.HeadingTextLable}`}
                      >
                        Password
                      </Typography>
                      <TextValidator
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                          marginBottom: "15px",
                        }}
                        id="outlined-adornment-password"
                        type={"text"}
                        fullWidth
                        placeholder="Enter Password"
                        className={classes.TextInput}
                        size="small"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        validators={["required"]}
                        errorMessages={["Password is required"]}
                      />
                      <div className={classes.refreshIcon}>
                        <Button
                          variant="text"
                          onClick={() =>
                            generatePassword(
                              `${studentFirstName} ${studentLastName}`,
                              setPassword
                            )
                          }
                        >
                          <RefreshIcon />
                        </Button>
                      </div>
                      <div className={classes.copyIcon}>
                        <Button
                          variant="text"
                          onClick={() => {
                            copyTrst(password);
                          }}
                        >
                          <ContentCopyIcon />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Typography className={classes.NoteHeader}>
                    Note:{" "}
                    <span className={classes.Notetext}>
                      Once you click on submit your access ID and password send
                      to the parent email ID
                    </span>
                  </Typography>
                </div>
              ) : null}
            </Card>
            {userRole === "TEACHER" && (
              <Card
                className={`mt-3`}
                style={{
                  padding: "20px",
                  textAlign: "left",
                  marginBottom: "60px",
                }}
              >
                <Typography className={classes.StudentPageMainHeading}>
                  School Details
                </Typography>
                <Row>
                  <Col md={4}>
                    <div className="mt-3">
                      <Typography className={`${classes.textLable}`}>
                        School Name
                      </Typography>
                      <Typography
                        className={classes.MenuItemText}
                        style={{ marginLeft: 0 }}
                      >
                        {userInfo?.schoolInfo?.name}
                      </Typography>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mt-3">
                      <Typography className={`${classes.textLable}`}>
                        School Contact Number
                      </Typography>
                      <Typography
                        className={classes.MenuItemText}
                        style={{ marginLeft: 0 }}
                      >
                        {userInfo?.schoolInfo?.phone}
                      </Typography>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mt-3">
                      <Typography className={`${classes.textLable}`}>
                        School Email ID
                      </Typography>
                      <Typography
                        className={classes.MenuItemText}
                        style={{ marginLeft: 0 }}
                      >
                        {userInfo?.schoolInfo?.email}
                      </Typography>
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </div>
        ) : (
          <Card
            style={{ padding: "25px", marginBottom: "60px" }}
            className={`mt-4 ${classes.CardContainer}`}
          >
            <Row>
              <Col md={6}>
                <Typography
                  className={`mb-3 ${classes.StudentPageMainHeading}`}
                >
                  Upload Your File*
                </Typography>
                <CommanDropDown
                  image={studentFile}
                  callback={handleSelectFile}
                  type={"studentFile"}
                />
              </Col>
              <Col md={6}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="text"
                    className={classes.downloadTemplteBtn}
                    onClick={handleDownloadTemplate}
                  >
                    <ExcelIcon />
                    <span>Download Template</span>
                  </Button>
                </div>
                <div className={`mt-3 ${classes.Informationcontainer}`}>
                  <Typography className={classes.infoText}>
                    The file should contain the following mandatory details like
                    students
                  </Typography>
                  <ol className={classes.reqiredFeildList}>
                    <li className={classes.infoText}>
                      <span>Student Id</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Student Name</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Age</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Grade</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Class</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Parent Name</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Parent Mobile Number</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Parent Email Id</span>
                    </li>
                    <li className={classes.infoText}>
                      <span>Subscription Level</span>
                    </li>
                  </ol>
                  <Typography className={classes.infoText2}>
                    <InfoOutlineIcon />
                    <span>
                      You can download the template and upload it here
                    </span>
                  </Typography>
                </div>
              </Col>
            </Row>
          </Card>
        )}
        <Footer
          callback={handleNavigateStudent}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {defaultErrorMessage && errorAlert && (
        <AlertBox
          type="error"
          message={defaultErrorMessage}
          stateName={CloseAlert}
        />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateStudent}
        />
      )}
      {defaultSuccess && defaultSuccessMessage && (
        <AlertBox
          type="success"
          message={defaultSuccessMessage}
          stateName={CloseAlert}
        />
      )}
      {successModal && bulkUpdateList && bulkUpdateList.length > 0 && (
        <BulkUploadSuccessModal
          type="success"
          data={bulkUpdateList}
          modalClose={closeModel}
        />
      )}
    </div>
  );
};

export default AddStudent;
