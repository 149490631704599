import React from "react";
import classes from "./auth.module.css";
import loginImage from "../../Assets/Images/LoginImage.png";
import { Typography } from "@mui/material";

const CommonImageContainer = () => {
  return (
    <div>
      <div className={classes.imageContainer}>
        <img src={loginImage} alt="MainLogo" className={classes.loginImage} />
    
      </div>
    </div>
  );
};

export default CommonImageContainer;
