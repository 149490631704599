import {
  Card,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./student.module.css";
import {
  SearchIcon,
  SortIcon,
  SortIconOpposite,
} from "../../../Assets/Icons/icons";
import { handleSortClick } from "../../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import DefaultPagination from "../../../Components/Pagination";

const StudentsBulkPreview = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.userLogin);
  const { studentsArray } = useSelector((state) => state.studentDatas);
  const [sortedStudents, setSortedStudents] = useState(studentsArray);

  useEffect(() => {
    // Sort the studentsArray based on the sortBy value
    const sortStudents = (a, b) => {
      // console.log("Sort function triggers");

      // Extract the field to sort by from the sortBy value
      const sortField = sortBy?.replace(/(-desc|-asc)/, "").trim();
      // Determine the sorting order (ascending or descending)
      const sortOrder = sortBy?.includes("desc") ? -1 : 1;

      // console.log(sortField, sortOrder);

      // Compare the values for sorting
      if (a[sortField] < b[sortField]) {
        return sortOrder;
      }
      if (a[sortField] > b[sortField]) {
        return -sortOrder;
      }
      return 0;
    };

    // Use the sorted array and update the state
    const sortedArray = [...studentsArray].sort(sortStudents);
    setSortedStudents(sortedArray);
  }, [sortBy, studentsArray]);

  // Sort the studentsArray based on the sortBy value
  const handleIntrest = (data) => {
    const intrest = data
      .split("_") 
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" ");
    return intrest;
  };
  return (
    <Card>
      <div className={classes.Searchbackground}>
        <TextField
          sx={{
            "& legend": {
              display: "none",
            },
            "& fieldset": {
              top: 0,
            },
          }}
          fullWidth
          placeholder="Search by Student Name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={`${classes.searchBar} ${classes.toggleSearchBar}`}
          id="firstNameinput"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          size="small"
        />
      </div>
      <div>
        <div className={`mb-3 ${classes.TableContainer}`}>
          <Table className={classes.TableClass}>
            <TableHead>
              <TableRow className={classes.TableHeaderRow1}>
                <TableCell
                  flex={1}
                  className={classes.firstCell}
                  style={{ minWidth: "135px" }}
                >
                  <span className={classes.TableHeaderText}>Student ID</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "student_id")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("student_id") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell
                  flex={1}
                  className={classes.firstCell}
                  style={{ minWidth: "135px" }}
                >
                  <span className={classes.TableHeaderText}>Student Name</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "name")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "80px" }}>
                  <span className={classes.TableHeaderText}>Age</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "age")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("age") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "90px" }}>
                  <span className={classes.TableHeaderText}>Grade</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "gradeId")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("gradeId") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "95px" }}>
                  <span className={classes.TableHeaderText}>Section</span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "section")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("section") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "135px" }}>
                  <span className={classes.TableHeaderText}>Parent Name</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "parent_name")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("parent_name") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "130px" }}>
                  <span className={classes.TableHeaderText}>country code</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "country_code")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("country_code") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "185px" }}>
                  <span className={classes.TableHeaderText}>
                    Parent Contact number
                  </span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "phone")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("phone") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "140px" }}>
                  <span className={classes.TableHeaderText}>
                    Parent Email ID
                  </span>
                  <span
                    onClick={() => handleSortClick(dispatch, sortBy, "email")}
                  >
                    {sortBy?.includes("desc") && sortBy?.includes("email") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "160px" }}>
                  <span className={classes.TableHeaderText}>
                    SubscriptionLevel
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "subscription")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("subscription") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "140px" }}>
                  <span className={classes.TableHeaderText}>Password</span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "password")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("password") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "160px" }}>
                  <span className={classes.TableHeaderText}>
                    Interest Section
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "interest_section")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("interest_section") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
                <TableCell flex={1} style={{ minWidth: "160px" }}>
                  <span className={classes.TableHeaderText}>
                  Subscribed By
                  </span>
                  <span
                    onClick={() =>
                      handleSortClick(dispatch, sortBy, "subscribed_by")
                    }
                  >
                    {sortBy?.includes("desc") &&
                    sortBy?.includes("subscribed_by") ? (
                      <SortIconOpposite />
                    ) : (
                      <SortIcon />
                    )}
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStudents
                ?.filter((item) => {
                  return (
                    // (item.student_id && item?.student_id.includes(search)) ||
                    item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
                    item?.parent_name
                      ?.toLowerCase()
                      .includes(search?.toLowerCase())
                  );
                })
                ?.map((item, index) => {
                  return (
                    <TableRow key={index} className={classes.TableBodyRow}>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.student_id}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.name}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.age}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography
                          className={classes.TableBodyText}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.gradeId && item.gradeId.toString().length < 3
                            ? `${item.gradeId} Years`
                            : item?.gradeId?.charAt(0).toUpperCase() +
                              item?.gradeId
                                ?.slice(1)
                                .toLowerCase()
                                .replaceAll("_", " ")}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography
                          className={classes.TableBodyText}
                          style={{ textTransform: "capitalize" }}
                        >
                          {`${
                            item.section.charAt(0).toUpperCase() +
                            item.section
                              ?.slice(1)
                              .toLowerCase()
                              .replaceAll("_", " ")
                          }`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.parent_name}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.country_code}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.phone}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.email}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.subscription}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {`${item.password}`}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                          {handleIntrest(item.interest)}
                        </Typography>
                      </TableCell>
                      <TableCell flex={1} className={classes.firstCell}>
                        <Typography className={classes.TableBodyText}>
                        {`${item.subscribed_by}`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <DefaultPagination count={studentsArray.length > 0} />
      </div>
    </Card>
  );
};

export default StudentsBulkPreview;
